<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <h2 class="mb-4">Averages</h2>
      <p class="mb-4">
        While many of us will never take a statistics course until university, we are introduced to
        the concept of an average early on. Teachers often mention the average grade on a test. If
        you're a sports fan, you may see that your favorite athlete gets x points per game, or
        scores y goals per game. These measures of quantities per game, are also averages.
      </p>
      <p class="mb-4">
        So how do we calculate an average? It is one of the easier statistics that we commonly deal
        with. If you have a set of numbers, the average is calculated as:
      </p>
      <p class="pl-6 mb-5">
        <stemble-latex
          content="$\text{Average}=\displaystyle{\frac{\text{Sum of numbers in the set}}{\text{Number of numbers in the set}}} = \frac{1}{n}\sum_i^n x_i$"
        />
      </p>

      <p class="mb-4">
        For instance, if there are 4 students who wrote a test and their grades were 54%, 87%, 62%,
        and 95%, the average grade for those 4 students would be calculated as:
      </p>

      <p class="pl-6 mb-5">
        <stemble-latex
          content="$\text{Average}=\displaystyle{\frac{54\% + 87\% + 62\% + 95\%}{4}} = 74.5\%$"
        />
      </p>

      <p class="mb-6">
        This tells us that the average grade on the test for those 4 students was 74.5%. Note that
        an average should NEVER be lower than the smallest number in the set and NEVER higher than
        the highest number in the set. It should fall somewhere in between.
      </p>

      <p class="mb-5">Calculate the average of each of the following sets of numbers:</p>

      <p class="mb-2">
        <stemble-latex content="$\text{a)}$" />
        <number-value :value="avg1num1" />
        <stemble-latex content="$\text{, }$" />
        <number-value :value="avg1num2" />
        <stemble-latex content="$\text{, and}$" />
        <number-value :value="avg1num3" />
      </p>

      <calculation-input
        v-model="inputs.avg1"
        class="mb-6"
        :prepend-text="'$\\text{Average:}$'"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        <stemble-latex content="$\text{b)}$" />
        <number-value :value="avg2num1" />
        <stemble-latex content="$\text{,}$" />
        <number-value :value="avg2num2" />
        <stemble-latex content="$\text{,}$" />
        <number-value :value="avg2num3" />
        <stemble-latex content="$\text{,}$" />
        <number-value :value="avg2num4" />
        <stemble-latex content="$\text{, and}$" />
        <number-value :value="avg2num5" />
      </p>

      <calculation-input
        v-model="inputs.avg2"
        class="mb-6"
        :prepend-text="'$\\text{Average:}$'"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        <stemble-latex content="$\text{c)}$" />
        <number-value :value="avg3num1" />
        <stemble-latex content="$\text{,}$" />
        <number-value :value="avg3num2" />
        <stemble-latex content="$\text{,}$" />
        <number-value :value="avg3num3" />
        <stemble-latex content="$\text{, and}$" />
        <number-value :value="avg3num4" />
      </p>

      <calculation-input
        v-model="inputs.avg3"
        class="mb-6"
        :prepend-text="'$\\text{Average:}$'"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question347',
  components: {
    CalculationInput,
    NumberValue,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        avg1: null,
        avg2: null,
        avg3: null,
      },
    };
  },
  computed: {
    avg1num1() {
      return this.taskState.getValueBySymbol('avg1num1').content;
    },
    avg1num2() {
      return this.taskState.getValueBySymbol('avg1num2').content;
    },
    avg1num3() {
      return this.taskState.getValueBySymbol('avg1num3').content;
    },
    avg2num1() {
      return this.taskState.getValueBySymbol('avg2num1').content;
    },
    avg2num2() {
      return this.taskState.getValueBySymbol('avg2num2').content;
    },
    avg2num3() {
      return this.taskState.getValueBySymbol('avg2num3').content;
    },
    avg2num4() {
      return this.taskState.getValueBySymbol('avg2num4').content;
    },
    avg2num5() {
      return this.taskState.getValueBySymbol('avg2num5').content;
    },
    avg3num1() {
      return this.taskState.getValueBySymbol('avg3num1').content;
    },
    avg3num2() {
      return this.taskState.getValueBySymbol('avg3num2').content;
    },
    avg3num3() {
      return this.taskState.getValueBySymbol('avg3num3').content;
    },
    avg3num4() {
      return this.taskState.getValueBySymbol('avg3num4').content;
    },
  },
};
</script>
